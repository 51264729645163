.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-shape-divider-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom svg {
  position: relative;
  display: block;
  width: calc(161% + 1.3px);
  height: 208px;
  transform: rotateY(180deg);
}